<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        {{ $tc('label.usuario', 1) }}
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          v-if="metadadosUsuario"
          :metadados-entidade="metadadosUsuario"
          :objeto="usuario"
          :input-layout="inputLayout"
          :layout-class="layoutClass"
          :somente-leitura="somenteLeitura || isUsuarioExternoMaster"
          ref="containerUsuario">
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-select
              id="usuario-tipo"
              name="usuario-tipo"
              :label="$t('label.tipo_usuario')"
              :items="tiposUsuario"
              item-text="texto"
              item-value="valor"
              v-model="usuario.tipo"
              @change="verificaTrocaUsuario"
              :disabled="!isNovoCadastro || somenteLeitura || isUsuarioExternoMaster"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-text-field
              :label="$t('label.matricula')"
              v-model="usuario.matricula"
              maxlength="50"
              counter
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.login')} *`"
              v-model="usuario.nomUsuario"
              required
              :rules="[rules.requiredText]"
              maxlength="100"
              counter
              :disabled="!isNovoCadastro || somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-autocomplete
              id="usuario-perfil"
              class="custom-autocomplete"
              :disabled="somenteLeitura || isUsuarioExternoMaster"
              required
              :rules="[rules.required]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.perfil', 1)} *`"
              v-model="usuario.idPerfil"
              :items="perfis"
              item-text="nomPerfil"
              item-value="id"
              :search-input.sync="searchInputPerfil"
              @click:append="triggerPerfil"
              @click.native="clickPerfil"
              clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.email')} *`"
              v-model="usuario.email"
              required
              :rules="[rules.requiredText, rules.isEmailValid]"
              maxlength="150"
              counter
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="`${$t('label.nome')} *`"
              v-model="usuario.nome"
              required
              :rules="[rules.requiredText]"
              maxlength="100"
              counter
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              :label="$t('label.sobrenome')"
              v-model="usuario.sobrenome"
              maxlength="150"
              counter
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFornecedor">
            <v-text-field
              :label="`${$t('label.cargo')} *`"
              v-model="usuario.cargo"
              maxlength="50"
              counter
              required
              :rules="[rules.requiredText]"
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFornecedor">
            <v-text-field
              :label="`${$t('label.documento')} *`"
              v-model="usuario.documento"
              maxlength="50"
              counter
              required
              :rules="[rules.requiredText]"
              :disabled="somenteLeitura"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-text-field
              :label="$t('label.cpf')"
              v-model="usuario.cpf"
              :disabled="somenteLeitura"
              v-mask="'###.###.###-##'"
              :return-masked-value="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-autocomplete
              id="usuario-estruturausuario"
              class="custom-autocomplete"
              :disabled="somenteLeitura"
              required
              :rules="[rules.required]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.estrutura_usuario', 1)} * `"
              v-model="usuario.idEstruturaUsuario"
              :items="estruturasUsuario"
              item-text="nomEstruturaUsuario"
              item-value="id"
              :search-input.sync="searchInputEstruturaUsuario"
              @click:append="triggerEstruturaUsuario"
              @click.native="clickEstruturaUsuario"
              @input="estruturaUsuarioSelecionada"
              clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-autocomplete
              id="usuario-superioresimediatos"
              class="custom-autocomplete"
              :disabled="!usuario.idEstruturaUsuario || somenteLeitura"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="$t('label.superior_imediato')"
              v-model="usuario.superioresImediatos"
              :items="superioresImediato"
              item-text="nomeCompleto"
              item-value="id"
              :search-input.sync="searchInputSuperiorImediato"
              @click:append="triggerSuperiorImediato"
              @click.native="clickSuperiorImediato"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroFuncionario">
            <v-autocomplete
              id="usuario-divisao"
              class="custom-autocomplete"
              :disabled="!usuario.idEstruturaUsuario || somenteLeitura"
              required
              :rules="[rules.requiredTags]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.divisao', 1)} * `"
              v-model="usuario.idDivisoes"
              :items="divisoes"
              item-text="nome"
              item-value="id"
              :search-input.sync="searchInputDivisao"
              @click:append="triggerDivisao"
              @click.native="clickDivisao"
              multiple
              chips
              deletable-chips
              @input="divisaoSelecionada"
              return-object>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes"
            v-if="cadastroCliente">
            <v-autocomplete
              id="usuario-tipo-verba"
              class="custom-autocomplete"
              :disabled="somenteLeitura"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.tipo_verba', 1)}`"
              v-model="usuario.tiposVerba"
              :items="listaTiposVerba"
              item-text="nome"
              item-value="id"
              clearable
              multiple
              chips
              deletable-chips
              return-object>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" slot="depois" v-if="cadastroFornecedor">
            <v-switch
              color="primary"
              :label="$t('label.tipos_fornecedor.representante_legal')"
              :disabled="desabilitarFlagUsuarioMaster()"
              @click="triggerAtivaUsuarioMaster()"
              v-model="usuario.indUsuarioExternoMaster">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="2" slot="depois" v-if="cadastroFornecedor">
            <v-switch
              color="primary"
              :label="$t('label.procurador')"
              :disabled="somenteLeitura || !usuario.indUsuarioExternoMaster"
              v-model="usuario.indProcurador"
              @click="isProcurador(usuario.indProcurador)"
              >
            </v-switch>
          </v-col >
              <v-col cols="12" md="2" slot="depois" v-if="usuario.indUsuarioExternoMaster && flagProcurador">
                <v-menu
                  :key="index"
                  v-model="menuInicio"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :label="`${$tc('label.inicio_vigencia', 1)} * `"
                      :rules="[rules.requiredVigencia]"
                      readonly
                      :disabled="somenteLeitura"
                      v-model="usuario.dtaInicioVigencia"
                      prepend-icon="event"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="usuario.dtaInicioVigencia"
                    @input="menuInicio = false"
                    @change="dataAlterada(usuario.dtaInicioVigencia, null)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" slot="depois" v-if="usuario.indUsuarioExternoMaster && flagProcurador">
                <v-menu
                  v-model="menuFim"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :label="`${$tc('label.fim_vigencia', 1)} * `"
                      :rules="[rules.requiredVigencia]"
                      readonly
                      :disabled="somenteLeitura"
                      v-model="usuario.dtaFimVigencia"
                      prepend-icon="event"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    v-model="usuario.dtaFimVigencia"
                    @input="menuFim = false"
                    @change="dataAlterada(null, usuario.dtaFimVigencia)"
                  ></v-date-picker>
                </v-menu>
              </v-col>
          <v-col cols="12" sm="2" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.recebe_mail')"
              :disabled="somenteLeitura"
              v-model="usuario.indRecebeMail">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="2" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.ativo')"
              :disabled="desabilitarFlagAtivo()"
              @click="triggerAtivaUsuario(usuario.indAtivo)"
              v-model="usuario.indAtivo">
            </v-switch>
          </v-col>
        </metadados-container-layout>

        <div v-if="cadastroCliente">
          <hr class="primary" style="margin: 40px 0" />
          <v-row
            v-if="!somenteLeitura">
            <v-col cols="12" sm="4">
              <v-autocomplete
                id="usuario-cliente"
                class="custom-autocomplete"
                required
                :no-data-text="$t('message.nenhum_registro')"
                :label="`${$tc('label.cliente', 1)} * `"
                v-model="usuarioCliente"
                :items="clientes"
                item-text="codNome"
                item-value="id"
                :search-input.sync="searchInputCliente"
                @click:append="triggerCliente"
                @click.native="clickCliente"
                return-object
                clearable>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                color="primary"
                :label="$t('label.participa_aprovacao_acordo')"
                v-model="indAprovadorAcordo"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn class="accent"
                @click="adicionarCliente"
                :disabled="!usuarioClienteAdicionavel">
                {{ $t('label.adicionar') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="cabecalhoClientes"
            :items="usuario.clientes"
            :no-data-text="$t('message.nenhum_cliente_adicionado')"
            hide-default-footer
            disable-pagination
            :items-per-page="-1">
            <template v-slot:item.participa_aprovacao_acordo="{ item }">
              <v-switch
                color="primary"
                v-model="item.indAprovadorAcordo"
                :disabled="somenteLeitura"
              ></v-switch>
            </template>
            <template v-slot:item.indAtivo="{ item }">
              <v-switch
                color="primary"
                v-model="item.indAtivo"
                :disabled="somenteLeitura"
              ></v-switch>
            </template>
          </v-data-table>
        </div>
        <div v-if="usuario.indUsuarioExternoMaster && (podeCadastrarAnexos || podeVisualizarAnexos) && !cadastroFuncionario">
          <div class="mt-3 mb-2 title-float accent--text">
            {{$tc('label.anexo', 2)}}
          </div>
          <v-card>
            <v-container fluid grid-list-md>
              <usuario-form-arquivo
                :is-novo-cadastro="isNovoCadastro"
                :usuario="usuario"
                :pode-visualizar-anexos="(podeVisualizarAnexos && usuario.id && isUsuarioFornecedor) ? true : false"
                :pode-cadastrar-anexos="(podeCadastrarAnexos && isUsuarioFornecedor && usuario.id) ? true : false"
                :habilita-anexo-fornecedor="true"
                @FormAnexoArquivo__qtde="setQuantidadeArquivos">
              </usuario-form-arquivo>
            </v-container>
          </v-card>
        </div>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="somenteLeitura" @click.native="cancel">{{ $t('label.voltar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-if="!somenteLeitura" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
        <v-btn @click=""
          class="mr-3"
          dark
          color="red"
          @click.native="openSolicitarAprovacaoDialog"
          v-show="podeSolicitarAprovacao">
          {{ $t('label.solicitar_aprovacao') }}
        </v-btn>
        <v-btn @click=""
          dark
          color="primary"
          @click.native="openModalJustificativaAprovacao('APROVADO', $t('label.aprovar'))"
          v-show="podeAprovarAprovarRepresentanteLegal">
          {{ $t('label.aprovar') }}
        </v-btn>
        <v-btn @click=""
          class="mr-3"
          dark
          color="red"
          @click.native="openModalJustificativaAprovacao('REPROVADO', $t('label.reprovar'))"
          v-show="podeAprovarAprovarRepresentanteLegal">
          {{ $t('label.reprovar') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="dialog.content"
      :persistent="true"
      @agree="save()">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancel">
    </confirm>

    <confirm
      ref="avisoAnexoDialog" :title="$t('title.atencao')"
      :message="$t('message.aviso_anexo_obrigatorio')"
      :hide-disagree="true"
      :persistent="true"
      :agree-label="$t('label.ok')"
      @agree="desativarUsuario">
    </confirm>

    <confirm
      ref="avisoAnexoUsuarioAntigoDialog" :title="$t('title.atencao')"
      :message="$t('message.aviso_anexo_obrigatorio_solicitar_aprovacao_usuario')"
      :persistent="true"
      :hide-disagree="true"
      :agree-label="$t('label.ok')">
    </confirm>

    <usuario-form-termo-aceite-dialog
      ref="termoAceiteDialog"
      :termo-aceite="termoAceite"
      @Finalizar="finalizar">
    </usuario-form-termo-aceite-dialog>
    <usuario-aprovacao-dialog
      ref="modalJustificativaAprovacao"
      :title="titleJustificativaModal"
      :obrigatorio="statusAprovacaoUsuario !== 'APROVADO'"
      @Afirmativo="aprovarReprovarUsuario">
    </usuario-aprovacao-dialog>
    <usuario-data-alteracao-dialog
      ref="modalDataAlteracao"
      :nome-perfil-aprovador-fluxo="usuario.nomePerfilAprovador"
      @CancelarEdicaoData="cancelarEdicaoData"
      @SolicitarAprovacaoEdicaoData="openSolicitarAprovacaoDialog">
    </usuario-data-alteracao-dialog>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { montarEstruturaDinamica } from '../../common/functions/metadados';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { copyObject } from '../../common/functions/helpers';
import Usuario from './Usuario';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import UsuarioFormArquivo from './UsuarioFormArquivo';
import UsuarioFormTermoAceiteDialog from './UsuarioFormTermoAceiteDialog';
import UsuarioAprovacaoDialog from './UsuarioAprovacaoDialog';
import UsuarioDataAlteracaoDialog from './UsuarioDataAlteracaoDialog';

export default {
  name: 'UsuarioForm',
  props: {
    somenteLeitura: Boolean,
    default: false,
  },
  components: {
    Confirm,
    MetadadosContainerLayout,
    UsuarioFormArquivo,
    UsuarioFormTermoAceiteDialog,
    UsuarioAprovacaoDialog,
    UsuarioDataAlteracaoDialog,
  },
  computed: {
    ...mapGetters('metadados', [
      'getUsuarioMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
    ]),
    ...generateComputed('usr', [
      'canPost',
      'canEdit',
      'canSearch',
      'canAccessCRUD',
    ]),
    cadastroFornecedorNaoMaster() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONFIG_CAD_FORNECEDOR_NAO_MASTER').length;
    },
    permissaoSolicitarAprovacao() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'SOLICITAR_APROVACAO_USUARIO').length;
    },
    isNovoCadastro() {
      return !this.idUsuario;
    },
    cadastroFuncionario() {
      const ehCadastroFuncionario = this.usuario.tipo === 'FUNCIONARIO';

      if (ehCadastroFuncionario) {
        this.usuario.clientes = [];
      }

      return ehCadastroFuncionario;
    },
    usuarioLogadoFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    cadastroCliente() {
      const ehCadastroCliente = this.usuario.tipo === 'CLIENTE';

      if (ehCadastroCliente) {
        this.usuario.matricula = undefined;
        this.usuario.idEstruturaUsuario = undefined;
        this.usuario.superioresImediatos = [];
        this.usuario.idDivisoes = [];
      }

      return ehCadastroCliente;
    },
    cadastroFornecedor() {
      const ehCadastroFornecedor = this.usuario.tipo === 'FORNECEDOR';

      if (ehCadastroFornecedor) {
        this.usuario.clientes = [];
        this.usuario.idEstruturaUsuario = undefined;
        this.usuario.superioresImediatos = [];
        this.usuario.idDivisoes = [];
        this.usuario.tiposVerba = [];
      }

      return ehCadastroFornecedor;
    },
    metadadosUsuario() {
      if (this.getUsuarioMetadado && this.getUsuarioMetadado.mapaEntidades) {
        if (this.usuarioLogado.perfil.toLowerCase().includes('fornecedor') && this.getUsuarioMetadado.mapaEntidades.grupofornecedor) {
          delete this.getUsuarioMetadado.mapaEntidades.grupofornecedor;
          return this.getUsuarioMetadado;
        }
        return this.getUsuarioMetadado;
      }
      return undefined;
    },
    usuarioClienteAdicionavel() {
      return this.usuarioCliente
        && Object.keys(this.usuarioCliente).length > 0
        && this.usuarioCliente.id;
    },
    isUsuarioExternoMaster() {
      return this.usuarioLogado.indUsuarioExternoMaster;
    },
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    podeVisualizarAnexos() {
      return this.canSearch;
    },
    podeCadastrarAnexos() {
      return this.canAccessCRUD && this.isUsuarioFuncionario;
    },
    podeSolicitarAprovacao() {
      return ((this.usuario.statusFluxo && !['AGUARDANDO_APROVACAO', 'APROVADO'].includes(this.usuario.statusFluxo))
      || (!this.usuario.statusFluxo && this.usuario.id))
       && this.usuario.indUsuarioExternoMaster && !this.cadastroFuncionario && this.permissaoSolicitarAprovacao && !this.usuario.indAtivo;
    },
    podeAprovarAprovarRepresentanteLegal() {
      return this.usuarioLogado.idPerfil === parseInt(this.usuario.perfilAprovador, 10) && this.usuario.statusFluxo === 'AGUARDANDO_APROVACAO';
    },
  },
  data() {
    return {
      usuarioResource: this.$api.usuario(this.$resource),
      perfilResource: this.$api.perfil(this.$resource),
      estruturaUsuarioResource: this.$api.estruturaUsuario(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      extensaoResources: this.$api.extensao(this.$resource),
      resourceMetadado: this.$api.metadado(this.$resource),
      termoAceiteResources: this.$api.termoAceite(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      searchInputEstruturaUsuario: null,
      searchInputPerfil: null,
      searchInputSuperiorImediato: null,
      searchInputDivisao: null,
      searchInputCliente: '',
      dialog: {
        ref: 'dialog1',
        from: 'saveRegisterFromButton',
        title: this.$t('label.atencao'),
        content: '',
        action() {},
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredText: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredTags: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
        isEmailValid: (value) => this.emailReg.test(value) || this.$t('message.campo_obrigatorio_ou_invalido'),
        requiredVigencia: (value) => (!!value && !!value.trim() && this.usuario.indProcurador) || this.$t('message.campo_obrigatorio'),
      },
      tiposUsuario: [],
      perfis: [],
      estruturasUsuario: [],
      estruturaUsuario: null,
      superioresImediato: [],
      divisoes: [],
      clientes: [],
      listaTiposVerba: [],
      extensoes: [],
      niveisExtensoes: [],
      nivel: {},
      fluxoAprovacaoRepreLegal: false,

      usuario: new Usuario(),
      usuarioCliente: {},
      indAprovadorAcordo: false,
      grupoFornecedorEdicao: {},
      idGrupoFornecedorEdicao: null,

      inputLayout: {
        xs12: true,
        sm12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
        md12: false,
      },

      layoutClass: { wrap: true },

      cabecalhoClientes: [
        { text: this.$tc('label.cod_cliente', 1), sortable: true, value: 'idExterno' },
        { text: this.$tc('label.nome_cliente', 1), sortable: true, value: 'nomCliente' },
        { text: this.$t('label.participa_aprovacao_acordo'), value: 'participa_aprovacao_acordo', sortable: false },
        { text: this.$t('label.ativo'), value: 'indAtivo', sortable: false },
      ],
      termoAceite: null,
      quantidadeArquivos: 0,
      menuInicio: false,
      menuFim: false,
      flagProcurador: false,
      emailReg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      statusAprovacaoUsuario: '',
      justificativaAprovacaoReprovacaoUsuario: '',
      titleJustificativaModal: '',
      dataInicioVigenciaOriginal: '',
      dataFimVigenciaOriginal: '',
      index: 0,
      usuarioSemEdicao: {},
      existeTermoAceite: false,
    };
  },
  watch: {
    searchInputPerfil(val) {
      if (this.usuario && this.usuario.idPerfil) {
        const selecao = this.perfis.filter((perfil) => perfil.id === this.usuario.idPerfil);
        if (selecao.length > 0 && selecao[0].nomPerfil === val) {
          return;
        }
      }

      if (this.timeoutPerfil) {
        window.clearTimeout(this.timeoutPerfil);
      }
      this.timeoutPerfil = window.setTimeout(() => {
        if (val != null) this.buscarPerfis(val);
      }, 500);
    },
    searchInputEstruturaUsuario(val) {
      if (this.usuario && this.usuario.idEstruturaUsuario) {
        const selecao = this.estruturasUsuario
          .filter((est) => est.id === this.usuario.idEstruturaUsuario);
        if (selecao.length > 0 && selecao[0].nomEstruturaUsuario === val) {
          return;
        }
      }

      if (this.timeoutEstrutura) {
        window.clearTimeout(this.timeoutEstrutura);
      }
      this.timeoutEstrutura = window.setTimeout(() => {
        if (val != null) this.buscarEstruturasUsuario(val);
      }, 500);
    },
    searchInputSuperiorImediato(val) {
      if (this.timeoutSuperiorImediato) {
        window.clearTimeout(this.timeoutSuperiorImediato);
      }
      this.timeoutSuperiorImediato = window.setTimeout(() => {
        if (val != null) this.buscarSuperioresImediatos(val);
      }, 500);
    },
    searchInputDivisao(val) {
      if (this.timeoutDivisao) {
        window.clearTimeout(this.timeoutDivisao);
      }
      this.timeoutDivisao = window.setTimeout(() => {
        if (val != null) this.buscarDivisoes(val);
      }, 500);
    },
    searchInputCliente(val) {
      if (this.usuarioCliente && this.usuarioCliente.codNome) {
        const selecao = this.usuarioCliente.codNome;
        if (selecao === val) {
          return;
        }
      }

      if (this.timeoutCliente) {
        window.clearTimeout(this.timeoutCliente);
      }
      this.timeoutCliente = window.setTimeout(() => {
        if (val != null) this.buscarClientes(val);
      }, 500);
    },
    cadastroFuncionario() {
      if (!this.cadastroFuncionario && this.isUsuarioExternoMaster) {
        this.montarCadastroUsuarioExterno();
      }
    },
  },
  methods: {
    copyObject,
    ...mapActions('metadados', [
      'setUsuarioMetadado',
    ]),
    isProcurador(isProcurador) {
      this.flagProcurador = isProcurador;
      this.$forceUpdate();
    },
    popularMetadadosUsuario() {
      if (this.getUsuarioMetadado) {
        return;
      }
      this.setUsuarioMetadado({
        resource: this.resourceMetadado.listarUsuario,
      });
    },
    limparFormulario() {
      this.usuario = new Usuario();
      this.$refs.form.reset();
    },
    cancel() {
      this.limparFormulario();
      this.$router.push({ name: 'usuario' });
    },
    buscarTipos() {
      this.tiposUsuario = [];
      return this.usuarioResource.tipos()
        .then((res) => {
          res.data.forEach((item) => {
            const tipo = {
              texto: this.$t(item.texto),
              valor: item.valor,
            };
            this.tiposUsuario.push(tipo);
            if (!this.$route.params.id) {
              this.usuario.tipo = 'FORNECEDOR';
            }
          }, this);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    estruturaUsuarioSelecionada() {
      this.usuario.superioresImediatos = [];
      this.usuario.divisoes = [];
      this.usuario.idDivisoes = [];
    },
    adicionarCliente() {
      if (this.usuario.clientes.find((item) => item.id === this.usuarioCliente.id)) {
        this.$toast(this.$t('errors.cliente.ja_adicionado'));
        return;
      }
      this.usuarioCliente.indAtivo = true;
      this.usuarioCliente.indAprovadorAcordo = this.indAprovadorAcordo;
      this.usuario.clientes.push(this.usuarioCliente);
      this.usuarioCliente = {};
      this.indAprovadorAcordo = false;
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    finalizar() {
      this.$refs.confirmDialog.open();
    },
    openSaveDialog(ref, from) {
      if (!this.$refs.form.validate()) return;
      const content = this.$t('message.salvar_usuario');
      this.setDialogContent(content, this.save, from || this.dialog.from);
      if (this.termoAceite.indAtivo && !this.cadastroFuncionario && !this.usuarioLogadoFuncionario && !this.existeTermoAceite) {
        this.$refs.termoAceiteDialog.open(this.usuario.email, 'razaoSocial', `${this.usuario.nome} ${this.usuario.sobrenome ? this.usuario.sobrenome : ''}`);
      } else if (this.usuario.indUsuarioExternoMaster && this.podeCadastrarAnexos
          && this.quantidadeArquivos < 1 && !this.cadastroFuncionario) {
        this.save();
      } else {
        this.$refs.confirmDialog.open();
      }
    },
    openSolicitarAprovacaoDialog(dataAlterada = false) {
      if (this.quantidadeArquivos < 1) {
        this.$refs.avisoAnexoUsuarioAntigoDialog.open();
      } else {
        this.save('AGUARDANDO_APROVACAO', dataAlterada);
      }
    },
    openModalJustificativaAprovacao(status, titleModal) {
      this.titleJustificativaModal = titleModal;
      this.statusAprovacaoUsuario = status;
      this.statusAprovadoRepresentanteLegal = status;
      this.$refs.modalJustificativaAprovacao.open();
    },
    aprovarReprovarUsuario(justificativa) {
      this.justificativaAprovacaoReprovacaoUsuario = justificativa;
      this.save(this.statusAprovadoRepresentanteLegal);
    },
    montarCadastroUsuarioExterno() {
      this.buscarPerfis(null, this.usuarioLogado.idPerfil);
      this.buscarExtensoes(null);
    },
    buscarExtensoes(idGrupoFornecedor = null) {
      const params = {
        idNivelExtensao: 3,
        indUsuarioExterno: true,
      };
      if (idGrupoFornecedor) {
        params.idExtensao = idGrupoFornecedor;
      }
      return this.extensaoResources.listarPorUsuario(params)
        .then((res) => {
          this.extensoes = res.data.resposta;
          if (this.extensoes.length === 1) {
            const [grupoForn] = this.extensoes;
            this.usuario.grupofornecedor = grupoForn;
            this.usuario.id_grupofornecedor = grupoForn.id;
            this.grupoFornecedorEdicao = grupoForn;
            this.idGrupoFornecedorEdicao = grupoForn.id;
            this.$refs.containerUsuario.refresh();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    save(statusFluxo = null, dataAlterada = false) {
      this.aplicarTrimCamposTexto();
      if (this.cadastroFuncionario) {
        this.usuario.indUsuarioExternoMaster = false;
      }
      const objArmazenamento = {
        ...this.usuario,
        ...this.$refs.containerUsuario.getValoresDependencias(),
      };
      objArmazenamento.statusFluxo = statusFluxo;
      objArmazenamento.justificativaAprovacao = this.justificativaAprovacaoReprovacaoUsuario;
      objArmazenamento.dataAlterada = dataAlterada || null;
      if (this.usuario.dtaInicioVigenciaPrevia) {
        objArmazenamento.dtaInicioVigenciaPrevia = this.usuario.dtaInicioVigenciaPrevia;
        objArmazenamento.dtaFimVigenciaPrevia = this.usuario.dtaFimVigenciaPrevia;
      }
      this.exibeAlertaLgpd();
      if (this.isNovoCadastro) {
        this.requestGravar(objArmazenamento, this.usuarioResource.gravar);
      } else {
        this.requestAtualizar(objArmazenamento, this.usuarioResource.atualizar);
      }
    },
    exibeAlertaLgpd() {
      if (this.indAmbienteProdutivo) return;

      const alertaLgpd = this.$t('message.lgpd');
      alert(alertaLgpd); // eslint-disable-line
    },
    aplicarTrimCamposTexto() {
      this.usuario.nomUsuario = this.usuario.nomUsuario.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
      this.usuario.email = this.usuario.email.trim();
      this.usuario.nome = this.usuario.nome.trim();
      if (this.usuario.matricula) {
        this.usuario.matricula = this.usuario.matricula.trim();
      }
      if (this.usuario.sobrenome) {
        this.usuario.sobrenome = this.usuario.sobrenome.trim();
      }
      if (this.usuario.cargo) {
        this.usuario.cargo = this.usuario.cargo.trim();
      }
      if (this.usuario.documento) {
        this.usuario.documento = this.usuario.documento.trim();
      }
    },
    requestAtualizar(object, resource) {
      resource({ id: object.id }, object)
        .then(() => {
          if (this.termoAceite.indAtivo && !this.cadastroFuncionario && !this.usuarioLogadoFuncionario && !this.existeTermoAceite) {
            this.salvarTermoAceite();
          }
          window.setTimeout(() => {
            this.$router.push({ name: 'usuario' });
            this.$toast(this.$t('message.atualizado_com_sucesso'));
          }, 500);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    requestGravar(object, resource) {
      resource(object)
        .then((res) => {
          this.usuario.id = res.data;
          if (this.termoAceite.indAtivo && !this.cadastroFuncionario && !this.usuarioLogadoFuncionario && !this.existeTermoAceite) {
            this.salvarTermoAceite();
          }
          window.setTimeout(() => {
            if (this.usuario.indUsuarioExternoMaster && this.podeCadastrarAnexos) {
              this.editUsuario(this.usuario.id);
            } else {
              this.$router.push({ name: 'usuario' });
              this.$toast(this.$t('message.adicionado_confira_tabela'));
            }
          }, 500);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarUsuario(id) {
      this.usuarioResource.buscar({ id })
        .then((res) => {
          this.editar(res.body);
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    editar(usuario) {
      this.buscarEstruturasUsuario(null, usuario.idEstruturaUsuario)
        .then(() => {
          this.usuario = new Usuario();
          this.usuario.construirAPartirDaConsultaCompleta({ ...usuario });
          this.isProcurador(this.usuario.indProcurador);
          if (this.canEdit && !this.isNovoCadastro && !usuario.indUsuarioExternoMaster
            && this.usuarioLogadoFuncionario && !this.cadastroFornecedorNaoMaster && !this.cadastroFuncionario) {
            this.somenteLeitura = true;
          }
          if (this.usuario.statusFluxo && this.usuario.statusFluxo === 'AGUARDANDO_APROVACAO') {
            this.somenteLeitura = true;
          }
          if (!this.somenteLeitura && this.$route.params.ind_novo_usuario && this.usuario.indUsuarioExternoMaster
            && this.podeCadastrarAnexos && !this.cadastroFuncionario) {
            this.$refs.avisoAnexoDialog.open();
          }
          if (this.usuario.dtaInicioVigencia && this.usuario.dtaFimVigencia) {
            this.dataInicioVigenciaOriginal = this.usuario.dtaInicioVigencia;
            this.dataFimVigenciaOriginal = this.usuario.dtaFimVigencia;
          }
          if (this.idGrupoFornecedorEdicao !== null && this.grupoFornecedorEdicao) {
            this.usuario.grupofornecedor = this.grupoFornecedorEdicao;
            this.usuario.id_grupofornecedor = this.idGrupoFornecedorEdicao;
          }

          montarEstruturaDinamica(usuario, this.metadadosUsuario, this.usuario);
          this.usuarioSemEdicao = copyObject(usuario);
        })
        .then(() => this.buscarPerfis(null, this.usuario.idPerfil))
        .then(() => this.buscarDivisoes())
        .then(() => this.buscarSuperioresImediatos());
    },
    setDialogContent(content, action, from) {
      this.dialog.content = content || this.$t('message.salvar_novo_registro');
      this.dialog.action = action || this.save;
      this.dialog.from = from || this.dialog.from;
    },
    triggerPerfil() {
      this.triggerClick('usuario-perfil');
    },
    clickPerfil() {
      this.buscarPerfis(this.searchInputPerfil);
    },
    buscarPerfis(content = '', idPerfil = null) {
      const params = {
        autocomplete: content,
        limit: 30,
        page: 0,
      };
      if (idPerfil) {
        params.idPerfil = idPerfil;
      }
      return this.perfilResource.selecao(params)
        .then((res) => {
          if (this.usuario.tipo === 'FORNECEDOR') {
            this.perfis = res.data.filter((perfil) => converterStringParaBoolean(perfil.indPerfilUsuarioExterno));
          } else {
            this.perfis = res.data.filter((perfil) => !converterStringParaBoolean(perfil.indPerfilUsuarioExterno));
          }
          if (this.perfis.length === 1) {
            this.usuario.idPerfil = this.perfis[0].id;
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    converterStringParaBoolean(perfil) {
      return (String(perfil).toLowerCase() === 'true');
    },
    verificaTrocaUsuario() {
      if (this.usuario.tipo !== 'FORNECEDOR') {
        this.perfis = [];
        this.usuario.idPerfil = null;
        this.usuario.indAtivo = true;
        this.usuario.indUsuarioExternoMaster = false;
        this.buscarPerfis();
      } else if (this.usuario.tipo === 'FORNECEDOR' && !this.cadastroFornecedorNaoMaster && this.usuarioLogadoFuncionario) {
        this.usuario.indUsuarioExternoMaster = true;
        this.usuario.indAtivo = false;
      }
    },
    triggerEstruturaUsuario() {
      this.triggerClick('usuario-estruturausuario');
    },
    clickEstruturaUsuario() {
      this.buscarEstruturasUsuario(this.searchInputEstrutura);
    },
    buscarEstruturasUsuario(content, idEstruturaUsuario) {
      const param = {
        autocomplete: content,
      };
      if (idEstruturaUsuario) {
        param.id = idEstruturaUsuario;
      }
      return this.estruturaUsuarioResource.selecao(param)
        .then((res) => {
          this.estruturasUsuario = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerSuperiorImediato() {
      this.triggerClick('usuario-superioresimediatos');
    },
    clickSuperiorImediato() {
      this.buscarSuperioresImediatos(this.searchInputSuperiorImediato);
    },
    buscarSuperioresImediatos(content = '') {
      const params = {
        nome: content,
        colunaOrdenacao: 'nome_completo',
      };
      if (this.usuario.idEstruturaUsuario) {
        params.idEstruturaUsuario = this.usuario.idEstruturaUsuario;
      }

      return this.usuarioResource.listarSuperior(params)
        .then((res) => {
          this.superioresImediato = res.data.resposta;
          if (this.usuario.superioresImediatos && this.usuario.superioresImediatos.length > 0) {
            this.superioresImediato = [...this.superioresImediato, ...this.usuario.superioresImediatos];
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerDivisao() {
      this.triggerClick('usuario-divisao');
    },
    clickDivisao() {
      this.buscarDivisoes(this.searchInputDivisao);
    },
    divisaoJaInserida(idDivisao) {
      return this.usuario.divisoes
        .filter((divisao) => divisao.id === idDivisao).length > 0;
    },
    buscaDivisaoPorId(id) {
      return this.divisoes.filter((divisao) => divisao.id === id)[0];
    },
    divisaoSelecionada(divisoes) {
      this.usuario.divisoes = [];
      divisoes.forEach((divisao) => {
        if (Object.keys(divisao).length > 0 && !this.divisaoJaInserida(divisao.id)) {
          this.usuario.divisoes.push(divisao);
        } else if (!this.divisaoJaInserida(divisao)) {
          this.usuario.divisoes.push(this.buscaDivisaoPorId(divisao));
        }
      });
      this.usuario.idDivisoes = this.usuario.divisoes.map((divisao) => divisao.id);
    },
    buscarDivisoes(content = null) {
      const params = {
        autocomplete: content,
        cadastroUsuario: true,
      };
      if (this.usuario.idEstruturaUsuario) {
        params.idEstruturaUsuario = this.usuario.idEstruturaUsuario;
      }
      return this.divisaoResource.autocomplete(params)
        .then((res) => {
          this.divisoes = res.data;

          if (this.usuario.divisoes && this.usuario.divisoes.length > 0) {
            this.divisoes = [...this.divisoes, ...this.usuario.divisoes];
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerCliente() {
      this.triggerClick('usuario-cliente');
    },
    clickCliente() {
      this.buscarClientes(this.searchInputCliente);
    },
    buscarClientes(content) {
      const params = {
        autocomplete: content || '',
        limit: 100,
      };
      this.clienteResource.selecao(params)
        .then((res) => {
          this.clientes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    buscarTermoAceite() {
      const param = { objetivo: 'CRIACAO_USUARIO_FORNECEDOR' };
      this.termoAceiteResources.buscarTermoPorObjetivo(param).then((resposta) => {
        this.termoAceite = resposta.data;
      }, (err) => {
        this.$error(this, err);
      });
    },
    buscarChaveFluxoRepresentanteLegal() {
      this.configSistemaResource.buscarChave({ chave: 'FLUXO_APROVACAO_USR_FORNE_REP_LEG' })
        .then((resp) => {
          this.fluxoAprovacaoRepreLegal = resp.body;
        });
    },

    salvarTermoAceite() {
      this.termoAceite.id_usuario_cadastrado = this.usuario.id;
      this.termoAceiteResources.salvarAceiteCadastroUsuario(this.termoAceite)
        .then()
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerAtivaUsuario(value) {
      if (!value || this.cadastroFuncionario) {
        this.usuario.indUsuarioExternoMaster = false;
      }
    },
    setQuantidadeArquivos(quantidade = 0) {
      this.quantidadeArquivos = quantidade;
    },
    desativarUsuario() {
      this.usuario.indAtivo = false;
    },
    desabilitarFlagUsuarioMaster() {
      return this.somenteLeitura || !this.cadastroFornecedorNaoMaster || !this.isUsuarioFuncionario;
    },
    desabilitarFlagAtivo() {
      return this.somenteLeitura || (this.fluxoAprovacaoRepreLegal && this.usuario.indUsuarioExternoMaster);
    },
    isUsuarioFornecedor() {
      return this.usuario.tipo === 'FORNECEDOR';
    },
    triggerAtivaUsuarioMaster() {
      if (!this.usuario.indUsuarioExternoMaster) {
        this.usuario.indProcurador = false;
        this.flagProcurador = false;
      }
      if (this.usuario.indUsuarioExternoMaster && this.quantidadeArquivos < 1 && !this.cadastroFuncionario) {
        this.usuario.indAtivo = false;
      }
      this.$forceUpdate();
    },
    editUsuario(id) {
      this.$router.push({ name: 'editarUsuario', params: { id, ind_novo_usuario: true } });
      window.setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    openModalAlteracaoData() {
      this.$refs.modalDataAlteracao.open();
    },
    dataAlterada(dataInicio, dataFim) {
      if ((this.usuario.indProcurador && this.usuarioSemEdicao.dtaInicioVigencia && this.usuarioSemEdicao.dtaFimVigencia)
        && ((dataInicio !== null && this.dataInicioVigenciaOriginal !== dataInicio)
      || (dataFim !== null && this.dataFimVigenciaOriginal !== dataFim))) {
        this.openModalAlteracaoData();
      }
    },
    cancelarEdicaoData() {
      this.usuario.dtaInicioVigencia = this.dataInicioVigenciaOriginal;
      this.usuario.dtaFimVigencia = this.dataFimVigenciaOriginal;
      this.index += 1;
    },
    buscaTermoAceiteUsuarioCadastro(idUsuario) {
      this.usuarioResource.buscaTermoAceiteUsuarioCadastro(({ idUsuario }))
        .then((res) => {
          this.existeTermoAceite = res.body;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  beforeMount() {
    Promise.all([
      this.popularMetadadosUsuario(),
      this.buscarTipos(),
      this.buscarTermoAceite(),
      this.buscarChaveFluxoRepresentanteLegal(),
    ]).then(() => {});
    if (this.$route.params.id) {
      this.idUsuario = parseInt(this.$route.params.id, 10);
      this.carregarUsuario(this.idUsuario);
      this.buscaTermoAceiteUsuarioCadastro(this.idUsuario);
    }
  },
  mounted() {
    if (this.cadastroFornecedorNaoMaster || this.cadastroFuncionario) {
      this.usuario.indUsuarioExternoMaster = false;
    }
    if (!this.cadastroFornecedorNaoMaster && this.usuarioLogadoFuncionario) {
      this.usuario.indUsuarioExternoMaster = true;
    }
    if (this.usuario.statusFluxo && this.usuario.statusFluxo === 'AGUARDANDO_APROVACAO') {
      this.somenteLeitura = true;
    }
    if (this.usuarioLogado.tipo === 'FORNECEDOR') {
      this.buscarExtensoes(this.usuarioLogado.idGrupoFornecedor);
    }
    window.setTimeout(() => {
      if (!this.$route.params.id && this.usuario.indUsuarioExternoMaster) {
        this.usuario.indAtivo = false;
      }
      if (this.cadastroFuncionario) {
        this.usuario.indAtivo = true;
      }
    }, 500);
  },
};
</script>
